
import { defineComponent, onMounted, computed, ref } from "vue";
import { useDropzone } from "vue3-dropzone";
import axios from "axios";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import AppService, {
  closeMedia,
  selectMedia,
  selectMultipleMedia,
  renderImageUrl,
  convertParams,
} from "@/core/services/AppService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";
import { image } from "@/store/model";
export default defineComponent({
  name: "Media-Modal",
  setup(props, context) {
    const store = useStore();
    let paged = 1;
    let check_count = 1;
    const listDataMedia = ref();
    const showMedia = computed(() => store.getters["getShowMedia"]);
    const isMultiple = computed(() => store.getters["getIsMultiple"]);
    const totalPages = ref();
    const totalMedia = ref();
    const breadcrumbData = ref<any[]>([]);
    const currentUser = computed(() => store.getters["currentUser"]);
    const actionCall = computed(() => store.getters["getActionCall"]);
    const selectMultipleMediaData = ref<image[]>([] as image[]);
    const showEditTitle = ref<boolean[]>([]);
    const currentParent = computed(() => store.getters["getCurrentParent"]);
    const search = ref("");
    const loadMoreMedia = () => {
      paged = paged + 1;
      store
        .dispatch(
          Actions.GET_DATA_MEDIA,
          convertParams({
            name: search.value,
            limit: 24,
            page: paged,
            order: "desc",
            order_by: "created_at",
            parent: currentParent.value,
          })
        )
        .then(() => {
          let data = store.getters["listDataMedia"];
          listDataMedia.value.push(...data);
        });
    };
    const onSearch = async () => {
      paged = 1;
      await store
        .dispatch(
          Actions.GET_DATA_MEDIA,
          convertParams({
            name: search.value,
            page: 1,
            limit: 24,
            order: "desc",
            order_by: "created_at",
          })
        )
        .then(() => {
          listDataMedia.value = store.getters["listDataMedia"];
        });
    };
    const saveFiles = (files) => {
      const formData = new FormData(); // pass data as a form
      formData.append("folder", currentParent.value);
      for (var x = 0; x < files.length; x++) {
        formData.append("upload_files", files[x]);
      }
      //console.log(currentParent.value);
      AppService.UploadImage(formData)
        .then(async (response) => {
          await store.dispatch(
            Actions.GET_DATA_MEDIA,
            convertParams({
              page: 1,
              name: search.value,
              order: "desc",
              limit: 24,
              order_by: "created_at",
              parent: currentParent.value,
            })
          );
          listDataMedia.value = await store.getters["listDataMedia"];
        })
        .catch((err) => {
          console.error(err);
        });
    };
    function onDrop(acceptFiles, rejectReasons) {
      saveFiles(acceptFiles); // saveFiles as callback
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    onMounted(async () => {
      breadcrumbData.value = await store.getters["getBreadcrumbData"];
      let param = {
        name: search.value,
        page: paged,
        limit: 24,
        order: "desc",
        order_by: "created_at",
      };
      if (currentParent.value !== "") {
        param["parent"] = currentParent.value;
      }
      await store
        .dispatch(Actions.GET_DATA_MEDIA, AppService.convertParams(param))
        .then(() => {
          listDataMedia.value = store.getters["listDataMedia"];
          totalPages.value = store.getters["getMediaPages"];
          totalMedia.value = store.getters["getTotalMedia"];
        });

      showEditTitle.value = Array(totalMedia.value).fill(false);
      // window.addEventListener("scroll", handleScroll);
    });
    const reloadMedia = async () => {
      //console.log(paged);
      await store.dispatch(
        Actions.GET_DATA_MEDIA,
        AppService.convertParams({
          name: search.value,
          page: 1,
          order: "desc",
          limit: 24,
          order_by: "created_at",
          parent: currentParent.value,
        })
      );
      totalPages.value = await store.getters["getMediaPages"];
      listDataMedia.value = await store.getters["listDataMedia"];
      totalMedia.value = await store.getters["getTotalMedia"];
      showEditTitle.value = Array(totalMedia.value).fill(false);
    };
    const updateCurrentFolder = async (parent) => {
      await store.commit("SetCurrentParent", parent);
      await store.commit("SetBreadcrumbData", breadcrumbData.value);
    };
    const deleteMedia = async (id, index) => {
      //console.log(id, index);
      Swal.fire({
        text: "Bạn chắc chắn muốn xóa chứ ?",
        icon: "warning",
        buttonsStyling: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-primary",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await store
            .dispatch("DELETE_MEDIA", id)
            .then(() => {
              delete listDataMedia.value[index];
              delete showEditTitle.value[index];
              //console.log(listDataMedia.value);
              Swal.fire({
                text: "Xóa thành công",
                icon: "success",
                buttonsStyling: false,
                showConfirmButton: false,
                timer: 1000,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              });
              reloadMedia();
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Vui lòng thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };
    const createNewFolder = async () => {
      let params = {
        parent: currentParent.value,
        broker_id: currentUser.value._id,
        name: "Thư mục mới",
      };
      await store.dispatch("NEW_FOLDER", params).then((data) => {
        let currentMedia = store.getters.currentMedia;
        listDataMedia.value.unshift(currentMedia);
      });
    };
    const selectMultipleMedia_ = async (
      actionCall,
      selectMultipleMediaData_
    ) => {
      selectMultipleMedia(actionCall, selectMultipleMediaData_);
      selectMultipleMediaData.value = [] as image[];
    };
    const updateMedia = async (image, index) => {
      let params = {
        id: image._id,
        name: image.originalname,
      };
      await store.dispatch("RENAME_MEDIA", params).then(() => {
        showEditTitle.value[index] = false;
      });
    };
    const rootFolder = async () => {
      breadcrumbData.value = [];
      await updateCurrentFolder("");
      search.value = "";
      await store.dispatch(
        Actions.GET_DATA_MEDIA,
        AppService.convertParams({
          name: search.value,
          page: 1,
          order: "desc",
          order_by: "created_at",
          parent: "",
        })
      );
      totalPages.value = await store.getters["getMediaPages"];
      listDataMedia.value = await store.getters["listDataMedia"];
      totalMedia.value = await store.getters["getTotalMedia"];
      showEditTitle.value = Array(totalMedia.value).fill(false);
    };
    const openFolder = async (parent) => {
      breadcrumbData.value.push(parent);
      search.value = "";
      await updateCurrentFolder(parent._id);

      await store.dispatch(
        Actions.GET_DATA_MEDIA,
        AppService.convertParams({
          name: search.value,
          page: 1,
          order: "desc",
          order_by: "created_at",
          parent: parent._id,
        })
      );
      totalPages.value = await store.getters["getMediaPages"];
      listDataMedia.value = await store.getters["listDataMedia"];
      totalMedia.value = await store.getters["getTotalMedia"];
      showEditTitle.value = Array(totalMedia.value).fill(false);
    };
    function clickBreadcrumb(index) {
      breadcrumbData.value.length = index + 1;
      search.value = "";
      updateCurrentFolder(breadcrumbData.value[index]._id);
      paged = 1;
      reloadMedia();
    }
    return {
      getRootProps,
      getInputProps,
      ...rest,
      listDataMedia,
      totalMedia,
      isMultiple,
      search,
      check_count,
      showMedia,
      actionCall,
      selectMultipleMediaData,
      selectMultipleMedia_,
      showEditTitle,
      breadcrumbData,
      selectMultipleMedia,
      clickBreadcrumb,
      openFolder,
      rootFolder,
      updateMedia,
      deleteMedia,
      createNewFolder,
      onSearch,
      closeMedia,
      selectMedia,
      renderImageUrl,
      loadMoreMedia,
    };
  },
});

const DocMenuConfig = [
  {
    pages: [
      {
        sectionTitle: "Quản trị viên",
        fontIcon: "bi-person",
        route: "/administrators",
        permission: "accounts",
        sub: [
          {
            sectionTitle: "Người dùng",
            permission: "accounts",
            sub: [
              {
                heading: "Thêm mới",
                route: "/administrators/accounts/add",
              },
              {
                heading: "Danh sách",
                route: "/administrators/accounts",
              },
            ],
          },
          {
            sectionTitle: "Nhóm quyền",
            permission: "roles",
            sub: [
              {
                heading: "Thêm mới",
                route: "/administrators/roles/add",
              },
              {
                heading: "Danh sách",
                route: "/administrators/roles",
              },
            ],
          },
          {
            sectionTitle: "Quyền",
            route: "/administrators/permissions",
            permission: "permissions",
            sub: [
              {
                heading: "Danh sách",
                route: "/administrators/permissions",
              },
              {
                heading: "Thêm Quyền",
                route: "/administrators/permissions/add",
              },
            ],
          },
        ],
      },
      {
        sectionTitle: "Quản lý nội dung",
        fontIcon: "bi-file-text",
        route: "/contents",
        permission: "contents",
        sub: [
          {
            sectionTitle: "Tin tức",
            permission: "news",
            sub: [
              {
                heading: "Thêm mới",
                route: "/contents/news/add",
              },
              {
                heading: "Danh sách",
                route: "/contents/news",
              },
            ],
          },
          {
            sectionTitle: "Review bốn phương",
            permission: "experiences",
            sub: [
              {
                heading: "Thêm mới",
                route: "/contents/experiences/add",
              },
              {
                heading: "Danh sách",
                route: "/contents/experiences",
              },
            ],
          },
          {
            sectionTitle: "Danh mục tin",
            permission: "news-categories",
            sub: [
              {
                heading: "Thêm mới",
                route: "/contents/news-categories/add",
              },
              {
                heading: "Danh sách",
                route: "/contents/news-categories",
              },
            ],
          },
          {
            heading: "Danh mục sản phẩm",
            route: "/contents/product-categories",
          },
          {
            heading: "SEO Tìm kiếm",
            route: "/contents/posts-search",
          },
        ],
      },
      {
        sectionTitle: "Quản lý dự án",
        fontIcon: "bi-building",
        route: "/projects",
        permission: "contents",
        sub: [
          {
            sectionTitle: "Dự án",
            permission: "projects",
            sub: [
              {
                heading: "Thêm mới",
                route: "/projects/add",
              },
              {
                heading: "Danh sách",
                route: "/projects",
              },
            ],
          },
          {
            sectionTitle: "Danh mục dự án",
            permission: "projects-categories",
            sub: [
              {
                heading: "Thêm mới",
                route: "/projects/project-categories/add",
              },
              {
                heading: "Danh sách",
                route: "/projects/project-categories",
              },
            ],
          },
        ],
      },
      {
        sectionTitle: "Quản lý đối tác",
        fontIcon: "bi-person",
        route: "/partners",
        permission: "partners",
        sub: [
          {
            sectionTitle: "Nhóm đối tác",
            permission: "partners-categories",
            sub: [
              {
                heading: "Thêm mới",
                route: "/partners/categories/add",
              },
              {
                heading: "Danh sách",
                route: "/partners/categories",
              },
            ],
          },
          {
            sectionTitle: "Đối tác",
            permission: "partners",
            sub: [
              {
                heading: "Thêm mới",
                route: "/partners/add",
              },
              {
                heading: "Danh sách",
                route: "/partners",
              },
            ],
          },
        ],
      },

      {
        sectionTitle: "Quản lý đại lý",
        fontIcon: "bi-person",
        route: "/agencies",
        permission: "agencies",
        sub: [
          {
            heading: "Thêm mới",
            route: "/agencies/add",
          },
          {
            heading: "Danh sách",
            route: "/agencies",
          },
        ],
      },
      {
        sectionTitle: "Cấu hình",
        fontIcon: "bi-gear",
        route: "/configs",
        permission: "configs",
        sub: [
          {
            heading: "Chung",
            permission: "configs",
            route: "/configs/general",
          },
          {
            heading: "Trang chủ",
            permission: "configs",
            route: "/configs/home",
          },

          {
            heading: "Banner Đại lý",
            permission: "configs",
            route: "/configs/agencies",
          },
          {
            heading: "Menu",
            permission: "configs",
            route: "/configs/menu",
          },
          {
            heading: "Vị trí tuyển dụng",
            permission: "configs",
            route: "/configs/position-career",
          },
          {
            heading: "Địa điểm làm việc",
            permission: "configs",
            route: "/configs/location-career",
          },
        ],
      },
      {
        sectionTitle: "Dữ liệu form",
        fontIcon: "bi-graph-up-arrow",
        route: "/configs",
        permission: "configs",
        sub: [
          {
            heading: "Định giá",
            permission: "configs",
            route: "/form-data/valuation",
          },
          {
            heading: "Đăng ký xem nhà",
            permission: "configs",
            route: "/form-data/post-register",
          },
          {
            heading: "CV ứng viên",
            permission: "configs",
            route: "/form-data/curriculum-vitae",
          },
          {
            heading: "Yêu cầu",
            permission: "configs",
            route: "/form-data/broker-request-upgrade",
          },
        ],
      },
    ],
  },
];
export default DocMenuConfig;
